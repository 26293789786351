html {
  @apply antialiased font-sans;
  text-rendering: optimizeLegibility;
}

body {
  @apply bg-gray-darker;

  /* Increase container padding at larger screens */
  .container {
    @screen md {
      @apply px-12;
    }
  }
}

.rich-text {
  p,
  ol,
  ul {
    margin-top: 1.25em;
    max-width: 60em;
  }
}

@responsive {
  .aspect-ratio-square {
    padding-bottom: 100%;
  }
}
