.btn-solid,
.btn-bordered {
  @apply inline-block border-2 transition-colors duration-200 uppercase font-bold py-4 px-6 text-center;
}

.btn-solid {
  @apply border-green bg-green text-white;

  &:hover {
    @apply border-green-dark bg-green-dark;
  }
}

.btn-bordered {
  @apply text-white;

  &:hover {
    @apply text-gray;
  }
}
